/**
 * Template class for Post Wordpress Post Type
 * @author Nicola Capovilla
 */
import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';
// Service
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';
// Component
import SiteComponent from '../../components/SiteComponent';
import Layout from '../../components/Layout';
import Strip from '../../components/Strip';
import NewsStrip from '../../components/NewsStrip';

// Styles
import styles from './style.module.css';

class Post extends SiteComponent {
	constructor(props) {
		super(props, true, { parent: 'news' });
	}

	goToNews(slug) {
		navigate(slug);
	}

	render() {
		const post = this.props.data.page;
		const news = this.props &&
		this.props.data &&
		this.props.data.news &&
		this.props.data.news.edges ?
		this.props.data.news.edges : [];
		const fields = {
			title: post.title,
			date: post.date,
			content: post.content,
			cover: post.acf.cover_image ? post.acf.cover_image.localFile.childImageSharp.fluid : null,
			summary: post.acf.summary,
		};

		/**
		 * News strip
		 **/
		const newsStrip = {
			news: news,
			baseUrlNews: `/${this.props.state.lang}/news`,
			title: 'homePage.newsStripTitle',
			buttonNews: 'homePage.goToNews',
		};

		return (
			<Layout metadata={this.props.data.page.yoast}>
				<Strip>
					{fields.cover == null ? (
						<h1 className={styles.newsTitleNoCover} dangerouslySetInnerHTML={{ __html: fields.title }} />
					) : (
						<div className={styles.newsCover}>
							<Img className={styles.newsPadding + ' ' + styles.newsImage} fluid={fields.cover} />
							<h1 className={styles.newsTitle} dangerouslySetInnerHTML={{ __html: fields.title }} />
						</div>
					)}
					<div className={styles.newsPaddingMore}>
						<div className={styles.newsSummary}>{fields.summary}</div>
						<div className={styles.newsContent} dangerouslySetInnerHTML={{ __html: fields.content }} />
					</div>
				</Strip>
				{news.length > 0 ? <NewsStrip hideButton={false} onClickCallback={this.goToNews} newsStrip={newsStrip} /> : <div/> }
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Post);

export const pageQuery = graphql`
	query($id: String!, $locale: String!) {
		page: wordpressPost(id: { eq: $id }) {
			title
			slug
			content
			date(formatString: "MMMM DD, YYYY")
			polylang_current_lang_code
			polylang_translations {
				id
				slug
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
			acf {
				summary
				cover_image {
					localFile {
						childImageSharp {
							fluid {
								sizes
								srcSet
								aspectRatio
								base64
								src
							}
						}
					}
				}
			}
		}
		news: allWordpressPost(
			limit: 3
			sort: { fields: [date], order: DESC }
			filter: { polylang_current_lang_code: { eq: $locale }, id: { ne: $id } }
		) {
			edges {
				node {
					id
					title
					lang: polylang_current_lang_code
					date
					slug
					acf {
						summary
						cover_image {
							localFile {
								childImageSharp {
									fluid {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
